.container-collitool {
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    justify-content: center;
    height: 3000px;
    color: #d1d1d1;
}

.background-collitool {
    position: absolute;
    top: 310px;
    width: 1000px;
    height: 2550px;
    background-color: #292929;
    border-radius: 20px;
}

.para-tabulation {
    text-indent: 2em;
}

h2 {
    margin-left: 10px;
}

.collitool {
    position: absolute;
    top: 90px;
    font-size: 10rem;
    font-family: "Cascadia Mono", serif;
}

.margin-collitool {
    margin: 2% 10% 0 10%;
    text-align: left;
}
.collitool-column {
    width: 400%;
}
.para-column {
    margin-left: 15px;
}

.para-tabulation-principal {
    text-indent: 2em;
}
.para-tabulation-secondaire {
    text-indent: 2em;
    display: none;
}


.titre-refresh-secondaire {
    display: none;
}
.para-tabulation-refresh-secondaire {
    display: none;
}

/* Image */
.Collitool-give {
    width: 100%;
}
.Collitool-no-give {
    width: 100%;
}
.Collitool-simplex-collision {
    width: 100%;
}
.Collitool-convex-collision {
    width: 100%;
}
.Collitool-complex-collision {
    width: 100%;
}

.para-tabulation-item-list-principal {
    display: block;
}
.titre-item-list-principal {
    display: block;
}
.titre-item-list-secondaire {
    display: none;
}
.para-tabulation-item-list-secondaire {
    display: none;
}

@media screen and (max-width: 1050px) {
    .background-collitool {
        position: absolute;
        top: 310px;
        width: 850px;
        height: 2550px;
        border-radius: 20px;
    }
}

@media screen and (max-width: 900px) {
    .background-collitool {
        position: absolute;
        top: 310px;
        width: 750px;
        height: 2400px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 2800px;
    }

    .collitool {
        position: absolute;
        top: 90px;
        font-size: 9rem;
        font-family: "Cascadia Mono", serif;
    }

    .para-tabulation-principal {
        text-indent: 2em;
        display: none;
    }
    .para-tabulation-secondaire {
        text-indent: 2em;
        display: block;
    }

}

@media screen and (max-width: 770px) {
    .background-collitool {
        position: absolute;
        top: 310px;
        width: 650px;
        height: 2500px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 2900px;
    }

    .collitool {
        position: absolute;
        top: 90px;
        font-size: 8rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 670px) {
    .background-collitool {
        position: absolute;
        top: 200px;
        width: 550px;
        height: 2600px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 2900px;
    }

    .collitool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
        font-family: "Cascadia Mono", serif;
    }

    .titre-refresh-principal {
        display: none;
    }
    .para-tabulation-refresh-principal {
        display: none;
    }
    .titre-refresh-secondaire {
        display: block;
        margin-left: 10px;
    }
    .para-tabulation-refresh-secondaire {
        display: block;
        text-indent: 2em;
    }

}

@media screen and (max-width: 570px) {
    .background-collitool {
        position: absolute;
        top: 200px;
        width: 450px;
        height: 2700px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 3000px;
    }

    .collitool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 470px) {
    .background-collitool {
        position: absolute;
        top: 200px;
        width: 350px;
        height: 3300px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 3600px;
    }

    .collitool {
        position: absolute;
        top: 90px;
        font-size: 4rem;
        font-family: "Cascadia Mono", serif;
    }

    .para-tabulation-item-list-principal {
        display: none;
    }
    .titre-item-list-principal {
        display: none;
    }
    .titre-item-list-secondaire {
        display: block;
    }
    .para-tabulation-item-list-secondaire {
        display: block;
    }
}

@media screen and (max-width: 350px) {
    .background-collitool {
        position: absolute;
        top: 200px;
        width: 250px;
        height: 3750px;
        border-radius: 20px;
    }
    .container-collitool {
        display: flex;
        justify-content: center;
        height: 4050px;
    }

    .collitool {
        position: absolute;
        top: 130px;
        font-size: 3rem;
        font-family: "Cascadia Mono", serif;
    }
}