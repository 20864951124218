.menuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13%;
    color: #090909;
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5em;
    background: #292929;
    border-color: #292929;
    transition: all .3s;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
}
.menuButton:active {
    color: #2e2e2e;
    box-shadow: inset 4px 4px 12px rgba(0, 0, 0, 0.24),
    inset -4px -4px 12px rgba(0, 0, 0, 0.24);
}

input[type = "checkbox"] {
    -webkit-appearance: none;
    display: none;
    visibility: hidden;
}

.menuButton span {
    width: 20px;
    height: 3px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    border-radius: 100px;
    transition: 0.3s ease;
}

input[type]:checked ~ span.top {
    transform: translateY(290%)rotate(45deg);
    width: 30px;
}

input[type]:checked ~ span.bot {
    transform: translateY(-270%) rotate(-45deg);
    width: 30px;
}

input[type]:checked ~ span.mid {
    transform: translateX(-20px);
    opacity: 0;
}
