.container-nomentool {
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    justify-content: center;
    height: 1500px;
}

.para-tabulation {
    text-indent: 2em;
}

h2 {
    margin-left: 10px;
}

.nomentool {
    position: absolute;
    top: 90px;
    font-size: 10rem;
    font-family: "Cascadia Mono", serif;
}

.background-nomentool {
    position: absolute;
    top: 310px;
    width: 1000px;
    height: 850px;
    background: #212121;
    border-radius: 20px;
    color: #d1d1d1;
}

.margin-nomentool {
    margin: 2% 10% 0 10%;
    text-align: left;
}

.Nomentool_Parameter{
    width: 100%;
}

.Nomentool_Limit {
    width: 100%;
}

@media screen and (max-width: 1050px) {
    .background-nomentool {
        position: absolute;
        top: 310px;
        width: 850px;
        height: 850px;
        border-radius: 20px;
    }

    .container-nomentool {
        display: flex;
        justify-content: center;
        height: 1400px;
    }
}

@media screen and (max-width: 900px) {
    .background-nomentool {
        position: absolute;
        top: 310px;
        width: 750px;
        height: 850px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 90px;
        font-size: 9rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 770px) {
    .background-nomentool {
        position: absolute;
        top: 310px;
        width: 650px;
        height: 850px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 90px;
        font-size: 8rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 670px) {
    .background-nomentool {
        position: absolute;
        top: 200px;
        width: 550px;
        height: 850px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 570px) {
    .background-nomentool {
        position: absolute;
        top: 200px;
        width: 450px;
        height: 900px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 470px) {
    .background-nomentool {
        position: absolute;
        top: 200px;
        width: 350px;
        height: 1000px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 90px;
        font-size: 4rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 375px) {
    .background-nomentool {
        position: absolute;
        top: 200px;
        width: 300px;
        height: 1100px;
        border-radius: 20px;
    }

    .nomentool {
        position: absolute;
        top: 130px;
        font-size: 3rem;
        font-family: "Cascadia Mono", serif;
    }
}

@media screen and (max-width: 350px) {
    .background-nomentool {
        position: absolute;
        top: 200px;
        width: 250px;
        height: 1300px;
        border-radius: 20px;
    }
    .container-nomentool {
        display: flex;
        justify-content: center;
        height: 1600px;
    }

    .nomentool {
        position: absolute;
        top: 130px;
        font-size: 3rem;
        font-family: "Cascadia Mono", serif;
    }
}