.container {
    position: relative;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 450px;
    align-content: center;
}
.Lightool {
    position: absolute;
    top: 90px;
    font-size: 10rem;
    font-family: "Cascadia Mono", serif;
}
.background {
    top: 390px;
    position: relative;
    width: 75%;
    height: 100%;
    padding-bottom: 30px;
    color: #d1d1d1;
    background: #212121;
    border-radius: 20px;
    font-family: "Cascadia Mono", serif;
}
.margin{
    margin: 2% 10% 0 10%;
    text-align: left;
}

p {
    margin-bottom: 10px;
    font-size: 1rem;
}

/* Texte en h1 */
h1 {
    margin-left: 50px;
}
.H1 {
    margin-top: 20px;
}

/* Texte en h2 */
h2 {
    margin-bottom: 10px;
}

/* Texte En h3 */
h3 {
    margin-left: 40px;
    margin-bottom: 10px;
}
.H3 {
    margin-left: 70px;
}

h4 {
    margin: 0 0 0 20px;
}

.para-tabulation-pp {
    margin-left: 20px;
    text-indent: 2em;
}

.column-paragraphe-light-dossier{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1100px;
    margin-bottom: 20px;
}

/* None */
.FlickerTimeScale {
    display: none;
}
.FlickerTimeScalep {
    display: none;
}
.FlickerIntensity {
    display: none;
}
.FlickerIntensityp {
    display: none;
}
.FlickerTimeOffset {
    display: none;
}
.FlickerTimeOffsetp {
    display: none;
}
.FlickerIntensity6 {
    display: none;
}
.paragraphe_lightool {
    display: none;
}


/* Image */
.Image_Dossier_Lightool {
    width: 70%;
}
.Image_Emissive_Lightool {
    margin: 20px 0 20px 50px;
}
.Image_Create_Light_Asset_Lightool {
    margin: 20px 0 20px 100px;
}
.Image_Generate_Light_Asset_Lightool {
    margin: 20px 0 20px 45px;
}
.Image_Create_Flicker_Lightool {
    margin: 20px 0 20px 100px;
}
.Image_Create_Flicker_Generate_Lightool {
    margin: 15px 0 0 0;
}

.Video_LightTool {
    width: 100%;
    cursor: pointer;
}
.Video_LightToolExample {
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
}

@media screen and (max-width: 1050px) {
    .FlickerTimeScale4 {
        display: none;
    }
    .FlickerTimeScale {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeScalep {
        margin-left: 70px;
        display: block;
    }

    .Image_Emissive_Lightool {
        margin: 10px 0 10px 10px;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 50px;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 0;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 45px;
    }
}

@media screen and (max-width: 1024px) {
    .background {
        position: absolute;
        top: 310px;
        width: 750px;
        height: 4000px;
        border-radius: 20px;
    }
    .container {;
        height: 4400px;
    }

    .FlickerTimeOffset4 {
        display: none;
    }
    .FlickerTimeOffset {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeOffsetp {
        margin-left: 70px;
        display: block;
    }

    .FlickerIntensity4 {
        display: none;
    }
    .FlickerIntensity {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerIntensityp {
        margin-left: 70px;
        display: block;
    }

    .Image_Emissive_Lightool {
        margin: 10px 0 10px 5px;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
    }
}

@media screen and (max-width: 770px) {
    .background {
        width: 650px;
        height: 3950px;
    }
    .container {
        padding-bottom: 50px;
    }

    .Lightool {
        position: absolute;
        top: 90px;
        font-size: 9rem;
        font-family: "Cascadia Mono", serif;
    }

    .FlickerTimeOffset4 {
        display: none;
    }
    .FlickerTimeOffset {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeOffsetp {
        margin-left: 70px;
        display: block;
    }
    .FlickerIntensity4 {
        display: none;
    }
    .FlickerIntensity {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerIntensityp {
        margin-left: 70px;
        display: block;
    }

    .Image_Emissive_Lightool {
        margin: 10px 0 10px 0;
        width: 100%;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
        width: 100%;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
}

@media screen and (max-width: 670px) {
    .background {
        top: 200px;
        width: 550px;
        height: 4050px;
    }

    .Lightool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
        font-family: "Cascadia Mono", serif;
    }
    .FlickerIntensity5 {
        display: none;
    }
    .FlickerTimeOffset4 {
        display: none;
    }
    .FlickerTimeOffset {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeOffsetp {
        margin-left: 70px;
        display: block;
    }
    .FlickerIntensity4 {
        display: none;
    }
    .FlickerIntensity {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerIntensityp {
        margin-left: 70px;
        display: block;
    }
    .FlickerIntensity6 {
        display: block;
        margin-left: 40px; /* ajustez la marge comme vous le souhaitez */
    }

    .Image_Emissive_Lightool {
        margin: 10px 0 10px 0;
        width: 100%;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
        width: 100%;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
}

@media screen and (max-width: 570px) {
    .background {
        position: absolute;
        top: 200px;
        width: 450px;
        height: 92%;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
        width: 100%;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Generate_Lightool {
        margin: 15px 0 0 0;
        width: 100%;
    }
}

@media screen and (max-width: 470px) {
    .background {
        width: 350px;
        height: 4550px;
    }
    .container {
        padding-bottom: 650px;
    }
    .Lightool {
        position: absolute;
        top: 90px;
        font-size: 5rem;
    }
    .para-tabulation-pp{
        margin-top: 200px;
        margin-left: -200px;
    }
    h1 {
        margin-left: 50px;
    }
    .H1 {
        margin-top: 5px;
    }

    /* Texte en h2 */
    h2 {
        margin-bottom: 10px;
    }

    /* Texte En h3 */
    h3 {
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .H3 {
        margin-left: 10px;
    }

    h4 {
        margin: 0 0 0 10px;
    }

    .pp {
        display: none;
    }
    .paragraphe_lightool {
        display: block;
    }

    .FlickerIntensity5 {
        display: none;
    }
    .FlickerTimeOffset4 {
        display: none;
    }
    .FlickerTimeOffset {
        display: block;
        margin-left: 5px;
    }
    .FlickerTimeOffsetp {
        margin-left: 10px;
        display: block;
    }
    .FlickerIntensity4 {
        display: none;
    }
    .FlickerIntensity {
        display: block;
        margin-left: 5px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerIntensityp {
        margin-left: 10px;
        display: block;
    }
    .FlickerTimeScale {
        display: block;
        margin-left: 5px; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeScalep {
        margin-left: 10px;
        display: block;
    }
    .FlickerIntensity6 {
        display: block;
        margin-left: 10px; /* ajustez la marge comme vous le souhaitez */
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
        width: 100%;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Generate_Lightool {
        margin: 15px 0 0 0;
        width: 100%;
    }
    .Image_Dossier_Lightool {
        width: 50%;
        margin-left: 70px;
    }
}

@media screen and (max-width: 370px) {
    .background {
        width: 250px;
        height: 5350px;
    }
    .container {
        padding-bottom: 1400px;
    }

    .para-tabulation-pp{
        margin-top: 200px;
        margin-left: -150px;
    }

    .Lightool {
        position: absolute;
        top: 130px;
        font-size: 3rem;
    }

    h1 {
        margin-left: -10px;
        font-size: 2rem

    }
    .H1 {
        margin-top: 5px;
    }

    /* Texte en h2 */
    h2 {
        margin-bottom: 5px;
    }

    /* Texte En h3 */
    h3 {
        margin-left: 0;
        margin-bottom: 10px;
    }
    .H3 {
        margin-left: 5px;
    }

    h4 {
        margin: 0 0 0 10px;
    }

    .pp {
        display: none;
    }
    .paragraphe_lightool {
        display: block;
    }

    .FlickerIntensity5 {
        display: none;
    }
    .FlickerTimeOffset4 {
        display: none;
    }
    .FlickerTimeOffset {
        display: block;
        margin-left: 0;
    }
    .FlickerTimeOffsetp {
        margin-left: 5px;
        display: block;
    }
    .FlickerIntensity4 {
        display: none;
    }
    .FlickerIntensity {
        display: block;
        margin-left: 0; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerIntensityp {
        margin-left: 5px;
        display: block;
    }
    .FlickerTimeScale {
        display: block;
        margin-left: 0; /* ajustez la marge comme vous le souhaitez */
    }
    .FlickerTimeScalep {
        margin-left: 5px;
        display: block;
    }
    .FlickerIntensity6 {
        display: block;
        margin-left: 5px; /* ajustez la marge comme vous le souhaitez */
    }

    .Image_Emissive_Lightool {
        margin: 10px 0 10px 0;
        width: 100%;
    }
    .Image_Create_Light_Asset_Lightool {
        margin: 10px 0 10px 5px;
        width: 100%;
    }
    .Image_Generate_Light_Asset_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Lightool {
        margin: 20px 0 20px 5px;
        width: 100%;
    }
    .Image_Create_Flicker_Generate_Lightool {
        margin: 15px 0 0 0;
        width: 100%;
    }
    .Image_Dossier_Lightool {
        width: 60%;
        margin-left: 40px;
    }
}