* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.navbar {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999;
  background: linear-gradient(#131313 0%, #000000 40%, #212121 100%);
}

.navbar a {
  color: #d129ff;
  font-weight: bolder;
}

.logo-img {
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

.navbar .nav-links ul {
  display: flex;
}

.navbar .nav-links ul li{
  margin:  10px 30px;
}

.navbarButton{
  position: absolute;
  top: 35px;
  right: 2%;
  display: none;
}

@media screen and (max-width: 820px) {
  .nav-links.open {
    margin: 0;
  }

  .navbar {
    padding: 0;
  }

  .navbarButton{
    display: block;
    margin-right: 10px;
  }

  .navbar .logo {
    font-size: 2em;
    font-weight: bold;
    display: flex;
    position: absolute;
    top: 35px;
    left: 35px;
  }

  .nav-links {
    top: 0;
    left: 0;
    position: fixed;
    background-color: #303030;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
  }

  .nav-links ul{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar .nav-links ul li{
    margin:  30px 10px;
    font-size: 1.5em;
  }
}

footer {
  height: 80px;
  position: relative;
  background-color: #2e2e2e;
}

.line {
  height: 6px;
  width: 100%;
  background-color: #424242;
}

.cercle-logo-social {
  position: relative;
}

.logo-link {
  margin-top: 1%;
  margin-left: 96%;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #0b66c3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
}

.linkedin-logo {
  position: relative;
  width: 100%;
  height: 100%;
}

.linkedin-logo-blue {
  width: 100%;
  height: 100%;
  display: none;
}

.cercle-logo-social:hover .linkedin-logo{
  display: none;
}

.cercle-logo-social:hover .linkedin-logo-blue{
  display: flex;
}
.logo-link:hover {
  background-color: #292929;
  box-shadow: 2px 2px 5px #000000;
}

@media screen and (max-width: 1500px){
  .logo-link {
    margin-left: 90%;
  }
}

@media screen and (max-width: 500px){
  .logo-link {
    margin-left: 80%;
  }
}

@media screen and (max-width: 400px){
  .logo-link {
    margin-left: 70%;
  }
}