.Home{
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #212121;
    padding-top: 20px;
    padding-bottom: 200px;
}
.codding {
    display: none;
}
.tooling {
    margin-top: 25px;
}
.container-home {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0rem, 26rem));
    justify-content: center;
    align-items: center;
    gap: 55px;
    margin-right: 1.5%;
    margin-top: 60px;
    margin-bottom: 30px;
}
.Titre-tolling {
    color: #bc02d4;
    margin-left: 2%;
    font-size: 2em;
    font-family: "Cascadia Mono", serif;
    font-weight: bold;
    pointer-events: none;
}

.Titre-modeling {
    color: #bc02d4;
    margin-left: 2%;
    font-size: 2em;
    font-family: "Cascadia Mono", serif;
    font-weight: bold;
    pointer-events: none;
}

/* Profile */
.profile-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0rem, 55rem));
    justify-content: center;
    align-items: center;
    margin: 30px auto 0;
    border-radius: 125px 25px 25px 125px;
    background-color: #292929;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
    width: 50%;
    height: 250px;
}
.border-test {
    position: absolute;
    width: 260px;
    height: 260px;
    background: #212121;
    border-radius: 128px 128px 128px 128px;
}
.img-profile-photo{
    margin-top: 5px;
    position: relative;
    width: 250px;
    height: 250px;
}
.p-profile {
    text-align: center;
    margin-top: 20px;
    margin-left: 250px;
    font-family: "Cascadia Mono", serif;
    color: #d1d1d1;
    pointer-events: none;
}
.p-lien {
    margin-left: 89%;
    margin-top: -25px;
    position: relative;
    color: #bc02d4;
    font-family: "Cascadia Mono", serif;
}
.p-lien:hover {
    text-shadow: 2px 2px 5px #000000;
}

/* Tooling */
.card-container {
    width: 450px;
    height: 450px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
.card {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.card .front-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}
.card .front-content p {
    font-size: 32px;
    font-weight: 700;
    opacity: 1;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}
.card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    color: #d1d1d1;
    line-height: 1.5;
    border-radius: 5px;
    transform: translateX(-96%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}
.padding {
    padding: 20px;
}
.card .content .heading {
    font-family: "Cascadia Mono", serif;
    font-size: 32px;
    font-weight: 700;
    margin-top: -20%;
    pointer-events: none;
}
.card:hover .content {
    transform: translateY(0);
}
.card:hover .front-content {
    transform: translateX(-30%);
}
.card:hover .front-content p {
    opacity: 0;
}
.collitool-lien {
    margin-top: 30px;
    margin-left: 300px;
    position: absolute;
    color: #d1d1d1;
    font-family: "Cascadia Mono", serif;
}
.collitool-lien:hover {
    text-shadow: 2px 2px 5px #000000;;
}
.paragraphe-collitool{
    font-family: "Cascadia Mono", serif;
    pointer-events: none;
}
.img-collitool {
    width: 100%;
    height: 100%;
}


/* Modeling */
.container-modeling {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0rem, 26rem));
    justify-content: center;
    align-items: center;
    gap:0;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 100px;
}
.card-modeling {
    position: relative;
    background: transparent;
    width: 300px;
    height: 300px;
    border: none;
}
.card-modeling:hover {
    width: 300px;
}
.card-modeling .container-image-modeling {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #292929;
    width: 240px;
    height: 240px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 3px 1px #1818183d, 2px 2px 3px #18181865, inset 2px 2px 2px #292929;
    transition: all .2s ease-in-out, opacity .3s;
    transition-delay: .5s, 0s;
}
.card-modeling:hover .container-image-modeling {
    opacity: 0;
    border-radius: 8px;
    transition-delay: 0s, .5s;
}
.card-modeling .container-image-modeling .image-circle-modeling {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(2px 2px 2px #1818188a);
    transition: all .3s ease-in-out;
    transition-delay: .5s;
}

.card-modeling .container-image-modeling .image-circle-modeling-guitare {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(2px 2px 2px #1818188a);
    transition: all .3s ease-in-out;
    transition-delay: .5s;
}
.card-modeling:hover .container-image-modeling .image-circle-modeling {
    opacity: 0;
    transition-delay: 0s;
}
.card-modeling .content-modeling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #292929;
    width: 240px;
    height: 240px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 3px 1px #1818183d, 2px 2px 3px #18181865, inset 2px 2px 2px #292929;
    visibility: hidden;
    transition: .3s ease-in-out;
    transition-delay: 0s;
    z-index: 1;
}
.card-modeling:hover .content-modeling {
    width: 340px;
    height: 240px;
    visibility: visible;
    transition-delay: .5s;
}
.card-modeling .content-modeling .detail-modeling {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .3s ease-in-out;
    transition-delay: 0s;
}
.card-modeling:hover .content-modeling .detail-modeling {
    color: #181818;
    opacity: 100%;
    transition: 1s;
    transition-delay: .3s;
}
.card-modeling .content-modeling .detail-modeling span {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 800;
}
.card-modeling .content-modeling .product-image-modeling {
    position: relative;
    width: 100%;
    height: 100%;
}
.card-modeling .content-modeling .product-image-modeling .box-image-modeling {
    display: flex;
    position: absolute;
    top: 0;
    left: -25%;
    width: 100%;
    height: 115%;
    opacity: 0;
    transform: scale(.5);
    transition: all .5s ease-in-out;
    transition-delay: 0s;
}
.card-modeling:hover .content-modeling .product-image-modeling .box-image-modeling {
    top: -25%;
    left: 0;
    opacity: 100%;
    transform: scale(1);
    transition-delay: .3s;
}
.card-modeling .content-modeling .product-image-modeling .box-image-modeling .img-product-modeling {
    width: 7rem;
    height: auto;
}
.card-modeling .content-modeling .product-image-modeling .box-image-modeling .img-product-modeling-guitare {
    width: 5rem;
    height: auto;
}
.fil-shoes1, .fil-shoes2 {
    fill: #333333
}
.margin-card-modeling {
    margin: 10px;
}
.margin-img-modeling {
    margin-top: 110px;
}

.margin-img-modeling-guitare {
    margin-top: 70px;
    margin-left:25px;
}

.p-card-modeling {
    color: #d1d1d1;
    margin-top: 20px;
    margin-left: 10px;
    font-family: "Cascadia Mono", serif;
}
.Titre-card {
    margin-left: 5px;
    font-weight: bold;
    font-size: 1.5em;
}
.p-lien-bague {
    display: none;
    margin-left: -80px;
    margin-top: 260px;
    position: relative;
    color: #bc02d4;
    font-family: "Cascadia Mono", serif;
}
.p-lien-bague:hover {
    text-shadow: 2px 2px 5px #000000;
}

/* Codding */
.container-codding {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0rem, 20rem));
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
    margin-bottom: 30px;
}
.card-codding{
    width: 300px;
    height: 200px;
    position: relative;
    margin-bottom: 120px;
}
.card-codding .face{
    width: 300px;
    height: 200px;
    transition: 0.5s;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
}
.card-codding .face.face1{
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(50px);
    border-radius: 20px;
}
.img-codding {
    margin-top: 4px;
    border-radius: 10px;
}
.card-codding:hover .face.face1{
    transform: translateY(0);
}
.card-codding .face.face2{
    position: relative;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transform: translateY(-100px);
    opacity: 0;
}
.card-codding:hover .img-codding {
    margin-top: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.card-codding:hover .face.face2{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform: translateY(0);
    opacity: 1;
}
.card-codding .face.face2 .content-codding{
    margin: 0;
    padding: 0;
}
.p-card-codding{
    font-family: "Cascadia Mono", serif;
    color: #d1d1d1;
}
.link-card-codding {
    margin-left: 180px;
    position: absolute;
}
.p-lien-codding{
    font-family: "Cascadia Mono", serif;
    color: #d1d1d1;
}
.p-lien-codding:hover {
    text-shadow: 2px 2px 5px #000000;
}
.titre-card-codding {
    margin-top: -40px;
    margin-left: 20px;
    font-size: 1.25em;
    font-family: "Cascadia Mono", serif;
    color: #d1d1d1;
}


@media screen and (max-width: 1850px){
    .p-profile {
        text-align: center;
        margin-left: 270px;
        margin-right: 20px;
    }
    .p-lien {
        margin-left: 85%;
    }
}
@media screen and (max-width: 1500px){
    .profile-card {
        margin-top: 200px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0rem, 55rem));
        justify-content: center;
        align-items: center;
        border-radius: 25px 25px 25px 25px;
        background-color: #292929;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
        width: 600px;
        height: 300px;
    }
    .border-test {
        margin-top: -400px;
        margin-left: 170px;
        position: absolute;
        width: 260px;
        height: 260px;
        border-radius: 128px 128px 128px 128px;
    }
    .img-profile-photo{
        margin-left: 5px;
        position: relative;
        width: 250px;
        height: 250px;
    }
    .p-profile {
        text-align: center;
        margin-left: 20px;
        margin-top: 80px;
        font-family: "Cascadia Mono", serif;
        color: #d1d1d1;
        pointer-events: none;
    }
    .p-lien {
        margin-left: 80%;
    }
}
@media screen and (max-width: 650px){
    .profile-card {
        margin-top: 100px;
        border-radius: 125px 125px 25px 25px;
        width: 250px;
        height: 770px;
    }
    .border-test {
        margin-top: -520px;
        margin-left: -5px;
    }
    .p-profile {
        text-align: center;
        margin-left: 20px;
        margin-top: 250px;
    }
    .p-lien {
        margin-bottom: -15px;
        margin-left: 50%;
    }
}
@media screen and (max-width: 500px){
    .container-wrapper {
        margin-right: auto;
        margin-left: auto;
    }
    .card-container {
        width: 350px;
        height: 350px;
    }
    .card-container {
        margin-left: auto;
    }
    .container-home {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 25rem));
    }
    .paragraphe-collitool{
        font-size: 0.8rem;
    }
    .collitool-lien {
        margin-top: 15px;
        margin-left: 220px;
    }
    .card .content .heading {
        margin-top: -15%;
    }
    .container-codding {
        margin-left: 10px;
    }
    .container-modeling {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 20rem));
        margin-left: 20px;
    }
    .container-codding {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 18rem));
        gap: 250px;
    }

}
@media screen and (max-width: 412px){
    .container-home {
        margin-left: -40px;
    }
    .paragraphe-collitool{
        font-size: 0.9rem;
    }
    .collitool-lien {
        font-size: 1rem;
        margin-top: -9px;
        margin-left: 210px;
    }
    .card .content .heading {
        margin-top: -5%;
    }
    .card-codding{
        margin-bottom: 0;
        margin-left: -5px;
    }
    .container-codding {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 18rem));
        gap: 170px;
    }
}
@media screen and (max-width: 400px){
    .card-container {
        width: 250px;
        height: 250px;
    }
    .card-container {
        margin-left: auto;
    }
    .container-home {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 15rem));
    }
    .paragraphe-collitool{
        font-size: 0.6rem;
    }
    .collitool-lien {
        font-size: 0.7rem;
        margin-top: -9px;
        margin-left: 150px;
    }
    .card .content .heading {
        margin-top: -5%;
    }
    .container-modeling {
        margin-left: -45px;
    }
    .container-codding {
        margin-left: 10px;
    }
    .card-modeling .container-image-modeling {
        width: 200px;
        height: 200px;

    }
    .card-modeling .container-image-modeling .image-circle-modeling {
        width: 140px;
    }

    .card-modeling .content-modeling .product-image-modeling .box-image-modeling .img-product-modeling-guitare {
        width: 4rem;
        height: auto;
    }

    .card-modeling .content-modeling {
        width: 200px;
        height: 200px;

    }
    .card-modeling:hover .content-modeling {
        width: 300px;
        height: 200px;
    }
    .p-lien-bague {
        display: none;
        margin-left: -80px;
        margin-top: 210px;
    }
    .card-modeling .content-modeling .product-image-modeling .box-image-modeling .img-product-modeling {
        width: 7rem;
        margin-top: -50px;
    }

    .card-modeling .content-modeling .product-image-modeling .box-image-modeling img-product-modeling-guitare {
        width: 7rem;
        margin-top: -50px;
    }


    .card-codding .face{
        width: 200px;
        height: 100px;
    }
    .container-codding {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 26rem));
        margin-left: 75px;
    }
    .card-codding:hover .face.face2{
        transform: translateY(100px);
        margin-left: -50px;
        margin-top: -50px;
        height: 200px;
        width: 300px;
    }
    .card-codding .face.face2{
        transform: translateY(-50px);
        margin-left: -50px;
        margin-top: -50px;
        height: 200px;
        width: 300px;
    }
    .card-modeling .container-image-modeling .image-circle-modeling-guitare {
        width: 80px;
    }
}
@media screen and (max-width: 330px){
    .card-codding .face{
        width: 200px;
        height: 100px;
    }
    .img-codding {
        width: 250px;
        height: 150px;
    }
    .container-codding {
        grid-template-columns: repeat(auto-fit, minmax(0rem, 26rem));
        margin-left: 45px;
    }
    .card-codding:hover .face.face2{
        transform: translateY(100px);
        margin-left: -25px;
        margin-top: -75px;
        height: 150px;
        width: 250px;
    }
    .card-codding .face.face2{
        transform: translateY(-10px);
        margin-left: -25px;
        margin-top: -75px;
        height: 150px;
        width: 250px;
    }
    .link-card-codding {
        margin-left: 130px;
    }
    .titre-card-codding {
        margin-top: -15px;
        font-size: 1em;
    }
    .p-card-codding{
        font-size: 0.8em;
    }
    .card-codding{
        margin-bottom: 0;
    }
    .container-codding {
        gap: 55px;
    }
    .container-home {
        margin-left: -5px;
    }

    .container-modeling {
        margin-left: -10px;
    }
    .card-modeling .container-image-modeling {
        width: 200px;
        height: 200px;

    }
    .card-modeling .container-image-modeling .image-circle-modeling {
        width: 140px;
    }
    .card-modeling .content-modeling {
        width: 200px;
        height: 200px;

    }
    .card-modeling:hover .content-modeling {
        width: 270px;
        height: 200px;
    }
    .p-lien-bague {
        margin-left: -100px;
        margin-top: 210px;
    }
    .card-modeling .content-modeling .product-image-modeling .box-image-modeling .img-product-modeling {
        width: 7rem;
        margin-top: -50px;
    }
}