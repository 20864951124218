.About {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    padding-top: 60px;
    padding-bottom: 181px;
    background: #212121;
    color: #d1d1d1;
    font-family: "Cascadia Mono", serif;
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
}
.titre-par-about {
    margin-left: 20px;
    font-weight: bold;
}

.card-container-about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Texte {
    flex: 1;
    padding-left: 50px;
    max-width: 1000px;
}

.card-software {
    margin-right: 50px;
    width: 240px;
    height: 550px;
    background-color: #292929;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.Title-software{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-software {
    width: 100%;
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-software {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-software-bot {
    margin-right: 50px;
    width: 240px;
    height: 550px;
    background-color: #292929;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.card-software-bot{
    display: none;
}

.Title-software-bot{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-software-bot {
    width: 100%;
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-software-bot {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-language {
    margin-left: 50px;
    width: 240px;
    height: 350px;
    background-color: #292929;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.Title-language{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-language {
    width: 100%;
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-language {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-about{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.titre, .paragraphe {
    width: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.image-text-container {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
}

.image {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}


@media screen and (min-width: 1900px) {
    .about-container {
        display: flex;
        justify-content: space-between;
        width: 1700px;
        margin: auto;
    }
}

@media screen and (max-width: 1800px) {
    .card-software-bot {
        display: block;
    }
    .card-software{
        display: none;
    }

    .Texte {
        justify-content: center;
    }
    .about-container {
        flex-direction: column;
    }

    .card-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .card-language {
        margin: 20px;
    }

    .card-software-bot {
        margin: 20px;
    }
}

@media screen and (max-width: 820px) {
    .About {
        padding-top: 110px;
    }
    .Texte {
        padding: 10px;
    }

    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .titre {
        margin-bottom: 10px;
    }

    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-language {
        margin-left: 20px;
    }

    .card-software-bot {
        margin-right: 20px;
    }
}

@media screen and (max-width: 500px) {
    .Texte {
        padding: 10px;
    }

    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .titre {
        margin-bottom: 10px;
    }

    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-language {
        margin: 10px;
    }

    .card-software-bot {
        margin: 10px;
    }
}

@media screen and (max-width: 280px) {

    .card-language {
        margin: 10px;
    }

    .card-software-bot {
        margin: 10px;
    }
}