.ToolingPage{
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #212121;
    padding-top: 20px;
    padding-bottom: 100px;
}
.cont-unreal {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pair-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 100px;
}
.column-unreal {
    text-align: center;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background: linear-gradient(-45deg, #bc02d4 0%, #2b1bfd 100% );
    width: 650px;
    height: 400px
}
.titre-collitool-unreal {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #d1d1d1;
}
.para-collitool-unreal {
    margin-right: 20px;
    margin-left: 20px;
    color: #d1d1d1;
}
.card-unreal {
    width: 650px;
    height: 400px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    transition: 0.2s ease-in-out;
    background-color: #292929;
}
.video_light {
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}
.link-card-tooling {
    color: #d1d1d1;
    position: absolute;
    margin-top: 30px;
    margin-left: 12%;
}
.link-card-tooling:hover {
    text-shadow: 2px 2px 5px #000000;
}

@media screen and (max-width: 1400px) {
    .pair-container {
        flex-direction: column;
        align-items: center;
    }
    .cont-unreal {
        flex-direction: column;
        align-items: center;
    }
    .column-unreal {
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .card-unreal {
        border-bottom-left-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}
@media screen and (max-width: 822px) {
    .ToolingPage{
        padding-top: 110px;
    }
}
@media screen and (max-width: 670px) {
    .cont-unreal {
        flex-direction: column;
        align-items: center;
    }

    .card-unreal {
        width: 550px;
        height: 350px;
    }

    .column-unreal {
        width: 550px;
        height: 400px;
    }
    .link-card-tooling {
        margin-top: -5px;
        margin-left: 25%;
    }
}
@media screen and (max-width: 560px) {
    .cont-unreal {
        flex-direction: column;
        align-items: center;
    }

    .card-unreal {
        width: 450px;
        height: 300px;
        margin-top: 20px;
    }

    .column-unreal {
        width: 450px;
        height: 500px;
    }
}
@media screen and (max-width: 460px) {
    .cont-unreal {
        flex-direction: column;
        align-items: center;
    }

    .card-unreal {
        width: 350px;
        height: 250px;
    }

    .column-unreal {
        width: 350px;
        height: 550px;
    }
    .link-card-tooling {
        margin-left: 20%;
    }
}
@media screen and (max-width: 360px) {
    .card-unreal {
        margin-top: 20px;
        width: 250px;
        height: 150px;
    }

    .column-unreal {
        width: 250px;
        height: 790px;
    }
    .link-card-tooling {
        margin-left: 10%;
        margin-top: -10px;
    }
}